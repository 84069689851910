<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'
import reportsMixin from '@/modules/Reports/reportsMixin'

export default {
  name: 'ReportProgramResumeList',
  components: {
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Datepicker: () => import('@/components/general/Datepicker'),
    FormSection: () => import('@/components/general/FormSection'),
    ModalConfirm: () => import('@/components/macro/ModalConfirm')
  },
  mixins: [ reportsMixin ],
  props: {

  },

  data () {
    return {
      formData: {
        dateInit: '',
        dateEnd: ''
      },

      reportId: null,
      modalWarning: false
    }
  },

  validations: {
    formData: {
      dateInit: {
        required,
        dateAfter: function (event) {
          return this.isDateAfter(new Date(event), new Date(this.formData.dateEnd))
        }
      },

      dateEnd: {
        required,
        dateBefore: function (event) {
          return this.isDateBefore(new Date(this.formData.dateInit), new Date(event))
        }
      }
    }
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetReportListPrograms',
      'attemptCheckReport',
      'attemptReportDownload'
    ]),

    getReportProgramResumeList () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      const initDate = moment(this.formData.dateInit)
      const finalDate = moment(this.formData.dateEnd)

      if (finalDate.diff(initDate, 'days') > 31) {
        this.modalWarning = true
      } else {
        this.generateReport()
      }
    },

    generateReport () {
      this.modalWarning = false
      const params = {
        createdDateInit: this.formData.dateInit,
        createdDateEnd: this.formData.dateEnd
      }

      this.setFetching(true)

      this.attemptGetReportListPrograms(params).then((response) => {
        this.reportId = response.data.id
        this.checkReport()
      }).catch(() => {
        this.setFetching(false)
        this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
      })
    },

    closeModal () {
      this.modalWarning = false
    },

    leave () {
      this.$router.push({ name: 'reports.index' })
    },

    downloadReport () {
      this.attemptReportDownload(this.reportId).then((response) => {
        const url = window.URL.createObjectURL(new Blob([ response.data ]))
        const link = document.createElement('a')

        link.href = url

        link.setAttribute(
          'download',
          this.$t('reports.generate:report.programs.list.file.name').toString()
        )

        document.body.appendChild(link)
        link.click()
      })
    },

    checkReport () {
      const interval = setInterval(() => {
        this.attemptCheckReport(this.reportId).then((response) => {
          if (response.data.status === 'generated') {
            this.setFetching(false)
            this.downloadReport()
            clearInterval(interval)
          } else if (response.data.status === 'failed') {
            this.setFetching(false)
            this.setFeedback({ message: this.$t('reports.error:failed.to.fetch') })
            clearInterval(interval)
          }
        })
      }, 5000)
    }
  }
}
</script>

<template>
  <div class="main-content reports-create">
    <ContentHeader
      :title="$t('reports:report.programs.resume.list.title')"
      light-theme
      fixed
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.reports')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave"
      />
      <ActionBar slot="actionbar" />
    </ContentHeader>
    <div class="main-content-inner">
      <div class="center">
        <div class="solutions-create-header">
          <h2
            class="solutions-create-title"
            v-html="$t('reports.generate:header.description')"
          />
        </div>
        <form class="form">
          <FormSection :title="$t('global:form.filters')">
            <Datepicker
              v-model="formData.dateInit"
              :label="$t('global:form.filters.date.init')"
              :validation="$v.formData.dateInit"
            />
            <Datepicker
              v-model="formData.dateEnd"
              :label="$t('global:form.filters.date.end')"
              :validation="$v.formData.dateEnd"
            />
          </FormSection>
          <Action
            slot="button"
            primary
            large
            fixed-width
            type="button"
            :text="$t('reports.generate:btn.generate')"
            @click="getReportProgramResumeList"
          />
        </form>
      </div>
    </div>
    <ModalConfirm
      :active="modalWarning"
      :title="$t('reports:report.programs.resume.list.title')"
      :description="$t('reports.generate:report.modal.description')"
      @cancelAction="closeModal"
      @confirmAction="generateReport"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/solutions.css"></style>
